import React from 'react';
import { List, TextField, Edit, SimpleForm, TextInput, NumberInput, EditButton } from 'react-admin';
import StyledDatagrid from './StyledDatagrid';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    inlineBlock: { display: 'inline-flex', marginRight: '1rem' },
});

export const EmissionTranslation = {
    emissions: {
        name: 'Utsläppsparameter |||| Utsläppsparametrar',
        fields: {
            code: 'Kod',
            name: 'Namn',
            description: 'Beskrivning',
            emissionValue: "Utsläppsvärde",
        },
    }
};

export const EmissionList = props => (
    <List {...props} bulkActionButtons={false}>
        <StyledDatagrid rowClick="edit">
            <TextField source="code" />
            <TextField source="name" />
            <TextField source="description" />
            <TextField source="emissionValue" />
            <EditButton />
        </StyledDatagrid>
    </List>
);

export const EmissionEdit = props => {
    const classes = useStyles();
    return (
        <Edit {...props}>
            <SimpleForm variant="outlined">
                <NumberInput source="code" disabled formClassName={classes.inlineBlock} />
                <TextInput source="name" disabled formClassName={classes.inlineBlock} />
                <TextInput source="description" formClassName={classes.inlineBlock} />
                <NumberInput source="emissionValue" formClassName={classes.inlineBlock}/>
            </SimpleForm>
        </Edit>
    )
};