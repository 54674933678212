import { createMuiTheme } from '@material-ui/core/styles';
import '../fonts.css';

const theme = createMuiTheme({
    palette: {
        secondary: {
            main: '#7c0e67'
        },
    },
    typography: {
        fontFamily: [
            'AdelleSansRegular, sans-serif'
        ],
    },
    overrides: {
        MuiCssBaseline: {
            '@global': {
                '@font-face': 'AdelleSansRegular',
            },
        },
    },

});

export const PrimaryTheme = theme;