import React from 'react';
import { List, TextField, NumberField, Edit, SimpleForm, TextInput, NumberInput, Toolbar, SaveButton } from 'react-admin';
import BatteryCharging80Icon from '@material-ui/icons/BatteryCharging80';
import StyledDatagrid from './StyledDatagrid';

export const ElectricityIcon = BatteryCharging80Icon;

export const ElectricityTranslation = {
    electricity: {
        name: 'El |||| El',
        fields: {
            name: "Beskrivning",
            emissionValue: "Utsläppsvärde"
        },
    }
};

export const ElectricityList = props => (
    <List {...props} bulkActionButtons={false}>
        <StyledDatagrid>
            <TextField source="name" />
            <NumberField source="emissionValue" />
        </StyledDatagrid>
    </List>
);

const ElectricityEditToolbar = props => (
    <Toolbar {...props} >
        <SaveButton />
    </Toolbar>
);


export const ElectricityEdit = props => (
    <Edit {...props}>
        <SimpleForm toolbar={<ElectricityEditToolbar />}>
            <TextInput source="id" disabled />
            <TextInput source="name" />
            <NumberInput source="emissionValue" />
        </SimpleForm>
    </Edit>
);