import React from 'react';
import { List, TextField  } from 'react-admin';
import BusinessIcon from '@material-ui/icons/Business';
import StyledDatagrid from './StyledDatagrid';

export const SupplierIcon = BusinessIcon;

export const SupplierTranslation = {
    suppliers: {
        name: 'Levenratör |||| Leverantörer',
        fields: {
            name: "Namn"
        },
    }
};


export const SupplierList = props => (
    <List {...props} bulkActionButtons={false}>
        <StyledDatagrid>
            <TextField source="name" />
        </StyledDatagrid>
    </List>
);