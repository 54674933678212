import React from 'react';
import { List, TextField, NumberField} from 'react-admin';
import EmojiFlagsIcon from '@material-ui/icons/EmojiFlags';
import StyledDatagrid from './StyledDatagrid';

export const CountryIcon = EmojiFlagsIcon;

export const CountryTranslation = {
    countries: {
        name: 'Land |||| Länder',
        fields: {
            name: "Namn",
            emissionValue: "Utsläppsvärde"
        },
    }
};

export const CountryList = props => (
    <List {...props} bulkActionButtons={false}>
        <StyledDatagrid>
            <TextField source="name" />
            <NumberField source="emissionValue" />
        </StyledDatagrid>
    </List>
);