import React from 'react';
import { Admin, Resource, ListGuesser, EditGuesser } from 'react-admin';
import { ProductList, ProductCreate, ProductEdit, ProductIcon } from "./components/Products";
import { SupplierList, SupplierIcon } from './components/Suppliers';
import { EmissionList, EmissionEdit } from './components/Emissions';
import { ElectricityList, ElectricityEdit, ElectricityIcon } from './components/Electricity';
import { CountryList, CountryIcon } from './components/Countries';
import { SweProvider } from './util/Translation';
import { PrimaryTheme } from './util/Theme';
import { BrowserRouter } from 'react-router-dom';
import Protected from './auth/Protected';
import { dataProvider } from './util/DataProvider';
import AuthService from './services/auth.service';
import { RegistrationList, RegistrationShow, RegisterIcon } from './components/Registrations';

const App = ({ baseUrl }) => {

    console.log("Rendering App.");

    const authService = new AuthService();

    return (
        <Protected authService={authService}>
            {
                ({ logout, getToken }) => {

                    // This is for react-admin to enable logout button
                    const auth = {
                        login: params => Promise.resolve(), // not implemented
                        logout: params => logout(),
                        checkAuth: params => Promise.resolve(), // not implemented
                        checkError: error => Promise.resolve(), // not implemented
                        getPermissions: params => Promise.resolve(), // not implemented
                    }

                    // Initiate data provider with getToken function
                    const provider = dataProvider.init(getToken);

                    return (
                        <BrowserRouter basename={baseUrl}>
                                <Admin dataProvider={provider} theme={PrimaryTheme} locale="sv" i18nProvider={SweProvider} authProvider={auth}>
                                    <Resource name="products" list={ProductList} edit={ProductEdit} create={ProductCreate} icon={ProductIcon} />
                                    <Resource name="suppliers" list={SupplierList} icon={SupplierIcon} />
                                    <Resource name="countries" list={CountryList} icon={CountryIcon} />
                                    <Resource name="electricity" list={ElectricityList} edit={ElectricityEdit} icon={ElectricityIcon} />
                                <Resource name="emissions" list={EmissionList} edit={EmissionEdit} />
                                <Resource name="registrations" list={RegistrationList} show={RegistrationShow} icon={RegisterIcon} />
                                </Admin>
                        </BrowserRouter>
                    )
                }
            }

        </Protected>
       
    );
};

export default App;
