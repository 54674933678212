import React from 'react';
import { List, TextField, EmailField, Show, SimpleShowLayout, ArrayField, Datagrid, SearchInput, Filter, DateField, downloadCSV  } from 'react-admin';
import ReceiptIcon from '@material-ui/icons/Receipt';
import StyledDatagrid from './StyledDatagrid';
import jsonExport from 'jsonexport/dist';

export const RegisterIcon = ReceiptIcon;

export const RegistrationTranslation = {
    registrations: {
        name: 'Registrering |||| Registreringar',
        fields: {
            firstName: 'Förnamn',
            lastName: 'Efternamn',
            email: 'E-postadress',
            companyName: "Företagsnamn",
            role: "Titel",
            telNo: "Telefonnummer",
            updateDate: "Skapad",
            details: {
                totalEmissions: "Totalt utsläpp",
                calculationSelections: "Produkter"
            }

        },
    }
};


const RegisterFilter = props => (
    <Filter {...props} variant="outlined">
        <SearchInput source="q" alwaysOn />
    </Filter>
);


const exporter = registrations => {

    jsonExport(registrations, { rowDelimiter: ';'}, (err, csv) => {
        // Add BOM for UTF-8
        csv = '\ufeff' + csv;
        downloadCSV(csv, 'registreringar'); 
    });
};


export const RegistrationList = props => (

    <List {...props} bulkActionButtons={false} filters={<RegisterFilter />} exporter={exporter}>
        <StyledDatagrid rowClick="show">
            <TextField source="id" />
            <DateField source="updateDate" showTime />
            <TextField source="firstName" />
            <TextField source="lastName" />
            <EmailField source="email" />
            <TextField source="companyName" />
            <TextField source="role" />
            <TextField source="telNo" />
        </StyledDatagrid>
    </List>
);


export const RegistrationShow = props => {

    console.log("Props: ", props);

    return (<Show {...props}>
        <SimpleShowLayout>
            <DateField source="updateDate" showTime />
            <TextField source="firstName" />
            <TextField source="lastName" />
            <EmailField source="email" />
            <TextField source="companyName" />
            <TextField source="role" />
            <TextField source="telNo" />
            <TextField source="details.totalEmissions" />
            <ArrayField source="details.calculationSelections">
                <Datagrid>
                    <TextField source="supplierDTO.name" label="Leverantör" />
                    <TextField source="productDTO.model" label="Modell" />
                    <TextField source="productDTO.type" label="Typ" />
                    <TextField source="quantity" label="Antal"/>
                    <TextField source="municipalityDTO.name" label="Leveransstad" />  
                </Datagrid>
            </ArrayField>

        </SimpleShowLayout>
    </Show>);
};