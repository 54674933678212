import * as React from "react";
import { Datagrid } from 'react-admin';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
    headerCell: {
        fontFamily: 'AdelleSansBold, sans-serif',
        color: '#6b737c',
        fontSize: '13px'
    },
});


const StyledDatagrid = ({ children, ...props }) => {
    const classes = useStyles();

    return (
        <Datagrid {...props} classes={{ headerCell: classes.headerCell }}>
            {children}
        </Datagrid>
    )
}

export default StyledDatagrid;