import { fetchUtils } from 'react-admin';
import jsonServerProvider from 'ra-data-json-server';

let getTokenFunc = null;

const httpClient = async (url, options = {}) => {

    if (!options.headers) {
        options.headers = new Headers({ Accept: 'application/json' });
    }

    const token = await getTokenFunc();

    options.headers.set('Authorization', `Bearer ${token.accessToken}`);

    return fetchUtils.fetchJson(url, options);
};

const init = (getToken) => {

    getTokenFunc = getToken;

    return jsonServerProvider('/api/admin', httpClient);
} 

export const dataProvider = {
    init
}