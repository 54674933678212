import polyglotI18nProvider from 'ra-i18n-polyglot';
import swedishMessages from 'ra-language-swedish';
import { ProductTranslation } from '../components/Products';
import { CountryTranslation } from '../components/Countries';
import { ElectricityTranslation } from '../components/Electricity';
import { SupplierTranslation } from '../components/Suppliers';
import { EmissionTranslation } from '../components/Emissions';
import { RegistrationTranslation } from '../components/Registrations';

// Fix, translation for unselect is missing. 
swedishMessages.ra.action.unselect = "";

const resourceTranslations = {
    resources: {
        ...ProductTranslation,
        ...CountryTranslation,
        ...ElectricityTranslation,
        ...SupplierTranslation,
        ...EmissionTranslation,
        ...RegistrationTranslation
    }
}

const messages = { ...swedishMessages, ...resourceTranslations};

const i18nProvider = polyglotI18nProvider(() => messages, 'sv');

export const SweProvider = i18nProvider;
