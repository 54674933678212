import React from 'react';
import { List, ReferenceInput, SelectInput, TextField, NumberField, EditButton, NumberInput, Create, Edit, SimpleForm, TextInput, Filter, SearchInput } from 'react-admin';
import ComputerIcon from '@material-ui/icons/Computer';
import StyledDatagrid from './StyledDatagrid';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    inlineBlock: { display: 'inline-flex', marginRight: '1rem' },
});

export const ProductIcon = ComputerIcon;

export const ProductTranslation = {
    products: {
        name: 'Produkt |||| Produkter',
        fields: {
            type: 'Typ',
            model: 'Modell',
            weight: 'Vikt (kg)',
            pcfValue: "PCF-värde",
            pcfShare: "PCF-share",
            energyDemand: "Energiåtgång",
            'supplier.name': "Leverantör",
            'productionCountry.name': 'Produktionsland',
            'supplier.id': "Leverantör",
            'productionCountry.id': 'Produktionsland'
        },
    }
};

const ProductFilter = props => (
    <Filter {...props} variant="outlined">
        <SearchInput source="q" alwaysOn />
    </Filter>
);

export const ProductList = props => {

    return (<List {...props} filters={<ProductFilter />}>
        <StyledDatagrid rowClick="edit">
            <TextField source="type" />
            <TextField source="model" />
            <NumberField source="weight" />
            <NumberField source="pcfValue" />
            <NumberField source="pcfShare" />
            <NumberField source="energyDemand" />
            <NumberField source="supplier.name" />
            <NumberField source="productionCountry.name" />
            <EditButton />
        </StyledDatagrid>
    </List>);
};

export const ProductCreate = (props) => {
    const classes = useStyles();
    return (
        <Create title="Create a Product" {...props}>
            <SimpleForm variant="outlined">
                <TextInput source="type" formClassName={classes.inlineBlock} />
                <TextInput source="model" formClassName={classes.inlineBlock} />
                <NumberInput source="weight" formClassName={classes.inlineBlock} />
                <NumberInput source="pcfValue" formClassName={classes.inlineBlock} />
                <NumberInput source="pcfShare" formClassName={classes.inlineBlock} />
                <NumberInput source="energyDemand" formClassName={classes.inlineBlock} />
                <ReferenceInput source="supplier.id" reference="suppliers" formClassName={classes.inlineBlock}>
                    <SelectInput optionText="name" />
                </ReferenceInput>
                <ReferenceInput source="productionCountry.id" reference="countries" formClassName={classes.inlineBlock}>
                    <SelectInput optionText="name" />
                </ReferenceInput>
            </SimpleForm>
        </Create>
    )
};

export const ProductEdit = (props) => {
    const classes = useStyles();
    return (
        <Edit title="Edit a Product" {...props} undoable={false}>
            <SimpleForm variant="outlined">
                <NumberInput source="id" disabled formClassName={classes.inlineBlock} />
                <TextInput source="type" formClassName={classes.inlineBlock} />
                <TextInput source="model" formClassName={classes.inlineBlock} />
                <NumberInput source="weight" formClassName={classes.inlineBlock} />
                <NumberInput source="pcfValue" formClassName={classes.inlineBlock} />
                <NumberInput source="pcfShare" formClassName={classes.inlineBlock} />
                <NumberInput source="energyDemand" formClassName={classes.inlineBlock} />
                <ReferenceInput source="supplier.id" reference="suppliers" formClassName={classes.inlineBlock}>
                    <SelectInput optionText="name" />
                </ReferenceInput>
                <ReferenceInput source="productionCountry.id" reference="countries" formClassName={classes.inlineBlock}>
                    <SelectInput optionText="name" />
                </ReferenceInput>
            </SimpleForm>
        </Edit>
    )
};