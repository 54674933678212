
import React, { useState, useEffect, useMemo, useCallback } from 'react';

const Protected = ({ authService, children }) => {

    let [user, setUser] = useState(null);

    let [loginFailed, setLoginFailed] = useState(false);

    let [inProgress, setInProgress] = useState(true);

    const logout = useCallback(() => {
        authService.logout();
    }, [authService]);

    const login = useCallback(() => {
        setLoginFailed(false);

        authService.login().then(
            user => {
                if (user) {
                    setUser(user);
                    setInProgress(false);
                } else {
                    setLoginFailed(true);
                    setInProgress(false);
                }
            },
            () => {
                setLoginFailed(true);
                setInProgress(false);
            }
        );
    }, [authService]);

    const getToken = useCallback(() => {
        return authService.getToken();
    }, [authService]);

    useEffect(() => {
       login(); 
        
    }, [authService, login]);
    

    // The authentication data to be passed to the children() if it's a function
    const childrenFunctionProps = useMemo(
        () => ({
            getToken,
            login,
            logout,
        }),
        [getToken, login, logout],
    );

    function getChildrenOrFunction(children, childrenProps) {
        if (children) {
            if (isChildrenFunction(children)) {
                return (children)(childrenProps);
            } else {
                return children;
            }
        } else {
            return null;
        }
    }

    function isChildrenFunction(children) {
        return typeof children == 'function' || false;
    }

    if (loginFailed) return <p>Något gick fel vid inloggning. Se till så att popup-fönster tillåts på denna sidan.</p>;    

    if (inProgress) return <p>Loggar in...</p>;

    return (
        user ? getChildrenOrFunction(children, childrenFunctionProps) : <p>Du måste logga in för att visa denna sidan.</p>    
    )
}

export default Protected;