import * as Msal from "@azure/msal-browser";

export default class AuthService {
    constructor() {
        console.info("Init AuthService");
        this.msalConfig = {
            auth: {
                clientId: process.env.REACT_APP_AZURE_CLIENT_ID, // Azure App client id
                authority: `https://login.microsoftonline.com/${process.env.REACT_APP_AZURE_TENANT_ID}` // Tenant ID
            },
            cache: {
                cacheLocation: "sessionStorage", // This configures where your cache will be stored
                storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
            },
            system: {
                loggerOptions: {
                    loggerCallback: (level, message, containsPii) => {
                        if (containsPii) {
                            return;
                        }
                        switch (level) {
                            case Msal.LogLevel.Error:
                                console.error(message);
                                return;
                            case Msal.LogLevel.Info:
                                console.info(message);
                                return;
                            case Msal.LogLevel.Verbose:
                                console.debug(message);
                                return;
                            case Msal.LogLevel.Warning:
                                console.warn(message);
                                return;
                        }
                    }
                }
            }
        };

        this.app = new Msal.PublicClientApplication(this.msalConfig);

        this.requestScope = {
            scopes: [`api://${process.env.REACT_APP_AZURE_API_ID}/access_as_user`]
        }

    }

    login = async (method) => {
        try {
            const loginResponse = await this.app.loginPopup(this.requestScope);
        } catch (err) {
            console.error(err);
        }

        const currentAccounts = this.app.getAllAccounts();

        if (currentAccounts === null) {
            // No user signed in
            return null;
        } else if (currentAccounts.length > 1) {
            // More than one user signed in, find desired user with getAccountByUsername(username)
            return currentAccounts[0];
        } else {
            return currentAccounts[0];
        }
    }

    logout = () => {
        this.app.logout();
    };

    getToken = () => {

        const users = this.app.getAllAccounts();

        const silentRequest = {
            account: users[0],
            scopes: this.requestScope.scopes
        }

        return this.app.acquireTokenSilent(silentRequest).then(
            accessToken => {
                console.info("Acquired token silently..");
                return accessToken;
            },
            error => {
                console.log("Got error: ", error);
                if (error instanceof Msal.InteractionRequiredAuthError) {
                    //console.info("Could not acquire silently:  ", error);
                    return this.app
                        .loginPopup(this.requestScope)
                        .then(
                            accessToken => {
                                return accessToken;
                            },
                            err => {
                                console.error(err);
                            }
                        );
                }

            }
        );
    };
}